import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Content.module.scss";
import Container from "../Container";

const Content = ({ className, children }) => (
  <Container>
    <div className={cx(styles.root, className)}>{children}</div>
  </Container>
);

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Content;
