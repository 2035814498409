import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./MarkdownContent.module.scss";

const MarkdownContent = ({ className, html }) => (
  <div
    className={cx(styles.root, className)}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

MarkdownContent.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string.isRequired,
};

export default MarkdownContent;
