import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import Content from "../common/Content";
import MarkdownContent from "../common/MarkdownContent";
import PrettyList from "../common/PrettyList";
import styles from "./Career.module.scss";

class Career extends React.Component {
  render() {
    const { location, siteTitle, career, otherCareers } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={career.frontmatter.title}
          description={career.frontmatter.description}
          keywords={[`trees`, `tree care`, `pruning`, `tree trimming`]}
        />
        <Hero title={career.frontmatter.title} />
        {Boolean(career.html) && (
          <Content>
            <MarkdownContent html={career.html} />
          </Content>
        )}
        {otherCareers.length > 0 && (
          <PrettyList
            className={styles.otherCareers}
            title={<h2>Other Positions</h2>}
            description={
              <p>Check out other opportunities at O'Brien Tree Care.</p>
            }
            items={otherCareers.map((otherCareer) => ({
              title: otherCareer.frontmatter.title || otherCareer.fields.slug,
              description:
                otherCareer.frontmatter.description || otherCareer.excerpt,
              slug: otherCareer.fields.slug,
            }))}
          />
        )}
      </Layout>
    );
  }
}

Career.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  career: PropTypes.object.isRequired,
  otherCareers: PropTypes.array.isRequired,
};

export default Career;
