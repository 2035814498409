import map from "lodash/map";
import React from "react";
import { graphql } from "gatsby";
import Career from "../components/Career";

class CareerTemplate extends React.Component {
  render() {
    const {
      location,
      data: { site, career, otherCareers },
    } = this.props;
    const siteTitle = site.siteMetadata.title;

    return (
      <Career
        location={location}
        siteTitle={siteTitle}
        career={career}
        otherCareers={map(
          otherCareers && otherCareers.edges,
          (edge) => edge.node
        )}
      />
    );
  }
}

export default CareerTemplate;

export const pageQuery = graphql`
  query CareerBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    career: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
    }
    otherCareers: allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fields: { slug: { glob: "/careers/**", ne: $slug } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
