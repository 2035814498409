import cx from "classnames";
import chunk from "lodash/chunk";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import Container from "../Container";
import styles from "./PrettyList.module.scss";

const PrettyList = ({
  className,
  columns = 3,
  items,
  listClassName,
  title,
  description,
}) => {
  const groups = chunk(items, columns);
  return (
    <div className={cx(styles.root, className)}>
      {title &&
        React.cloneElement(title, {
          className: cx(styles.title, title.props.className),
        })}
      {description &&
        React.cloneElement(description, {
          className: cx(styles.description, description.props.className),
        })}
      <Container>
        <ul className={cx(styles.list, listClassName)}>
          {groups.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              {group.map(({ title, description, slug, imgUrl }) => (
                <li
                  key={title}
                  className={cx(
                    styles.listItem,
                    {
                      [styles.twoColumns]: columns === 2,
                      [styles.threeColumns]: columns === 3,
                      [styles.singleRow]: groups.length === 1,
                      [styles.lastRow]: groupIndex === groups.length - 1,
                    },
                    description ? styles.hasDescription : styles.noDescription
                  )}
                >
                  {imgUrl && (
                    <Link
                      className={styles.itemImg}
                      style={{ backgroundImage: `url(${imgUrl})` }}
                      to={slug}
                      aria-hidden="true"
                    />
                  )}
                  <h3 className={styles.itemTitle}>
                    <Link className={styles.itemLink} to={slug}>
                      {title}
                    </Link>
                  </h3>
                  {description && (
                    <p className={styles.itemDescription}>{description}</p>
                  )}
                </li>
              ))}
            </React.Fragment>
          ))}
        </ul>
      </Container>
    </div>
  );
};

PrettyList.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.oneOf([1, 2, 3]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      slug: PropTypes.string,
      imgUrl: PropTypes.string,
    })
  ),
  listClassName: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
};

export default PrettyList;
